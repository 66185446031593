@charset "utf-8";
#IndexContainer{
	margin: auto;
	padding: 0;
	display: block;
	width: 100%;
}
#IndexContainer #SlideContainer{
	margin: 0;
    padding: 0;
    display: block;
    width: 100%;
    position: sticky;
    background-image: url(/images/index/word_background2.webp);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
}
#IndexContainer #SlideContainer #SearchSuperContainer{
	position: absolute;
	/* bring your own prefixes */
	transform: translate(0%, -50%);
}
#IndexContainer #SlideContainer #SearchContainer{
	border-radius: 30px;
	border: 1px solid #435477;
	background-color: #ffffff;
}
#IndexContainer #SlideContainer #SearchContainer #SearchInput{
	margin: 0;
	padding: 0;
	display: block;
	width: 100%;
	background-color: transparent;
}
#IndexContainer #SlideContainer #SearchContainer #SeacchImg{
	margin: 0;
	padding: 0;
	display: block;
	position: absolute;
}
#IndexContainer #SlideContainer #SearchContainer #SearchInput{
	border: none;
	text-align: left;
}
#IndexContainer #SlideContainer #QueryList{
	margin: 0;
	padding: 0;
	display: block;
	background-color: rgba(255, 255, 255, .9);
	position: absolute;
	
}
#IndexContainer #SlideContainer .QueryListResults{
	-webkit-box-shadow: 0px 0px 5px 0px rgba(67,84,119,1);
	-moz-box-shadow: 0px 0px 5px 0px rgba(67,84,119,1);
	box-shadow: 0px 0px 5px 0px rgba(67,84,119,1);
	border-radius: 4px;
}
#IndexContainer #SlideContainer #QueryList .QueryListItem{
	margin: 0;
	padding: 0;
	display: block;
	width: 100%;
	border-bottom: 1px solid #f0f0f0;
	cursor: pointer;
}
#IndexContainer #SlideContainer #QueryList .QueryListItem .QueryListItem_Image,
#IndexContainer #SlideContainer #QueryList .QueryListItem .QueryListItem_Name{
	margin: 0;
	padding: 0;
	display: inline-block;
	vertical-align: middle;
}
#IndexContainer #SlideContainer #QueryList .QueryListItem .QueryListItem_Image{
	background-position: center center;
    background-repeat: no-repeat;
    background-size:95%;
}
#IndexContainer #SlideContainer #QueryList .QueryListItem .QueryListItem_Name{
	color: #444444;
}
#IndexContainer #CollagesList{
	margin: auto;
	padding: 0;
	display: block;
	text-align: center;
	list-style: none;
}
#IndexContainer #CollagesList .CollagesListItem{
	border: 1px solid #435477;
	background-position: center center;
    background-repeat: no-repeat;
    background-size:90%;
	cursor: pointer;
	transition: .3s;
}
#IndexContainer #CollagesList .CollagesListItem:hover,
#IndexContainer #CollagesList .CollagesListItem:focus{
	transform: scale(0.98);
}
@media screen and (min-width: 701px) {
	#IndexContainer{
		width: 90%;
	}
	#IndexContainer #SlideContainer{
		height: 80vh;
	}
	#IndexContainer #SlideContainer #SearchSuperContainer{
		top: 45%;
		left: 3%;
		width: 100%;
	}
	#IndexContainer #SlideContainer #SearchContainer{
		width: 45%;
		padding: 15px 20px;
	}
	#IndexContainer #SlideContainer #SearchContainer #SearchInput{
		font-size: 1.2em;
	}
	#IndexContainer #SlideContainer #SearchContainer #SeacchImg{
		width: 30px;
		top: 15px;
		left: 45%;
		transform: translate(-20%, -15%);
	}
	#IndexContainer #SlideContainer #QueryList{
		width: 50%;
		max-height: 25vh;
		margin-top: 5px;
		top: 6vh;
	}
	#IndexContainer #SlideContainer #QueryList .QueryListItem{
		width: 100%;
		padding: 1vh 0;
	}
	#IndexContainer #SlideContainer #QueryList .QueryListItem .QueryListItem_Image{
		width: 10vw;
		height: 5vw;
		padding-right: 10px;
	}
	#IndexContainer #SlideContainer #QueryList .QueryListItem .QueryListItem_Name{
		font-size: 1.1em;
	}
	
	
	#IndexContainer #CollagesList{
		width: 100%;
		margin-top: 3vh;
	}
	#IndexContainer #CollagesList .CollagesListItem{
		margin: 0;
		padding: 0;
		display: inline-block;
		vertical-align: middle;
		margin: 3vh 2%;
		width: 35vw;
		height: 15vw;
	}
}
@media screen and (max-width: 700px) {
	#IndexContainer{
		width: 98%;
	}
	#IndexContainer #SlideContainer{
		height: 50vh;
		margin-top: 5vh;
	}
	#IndexContainer #SlideContainer #SearchSuperContainer{
		top: 10%;
		left: 3%;
		width: 100%;
	}
	#IndexContainer #SlideContainer #SearchContainer{
		width: 80%;
		padding: 15px 20px;
	}
	#IndexContainer #SlideContainer #SearchContainer #SearchInput{
		font-size: 1em;
	}
	#IndexContainer #SlideContainer #SearchContainer #SeacchImg{
		width: 30px;
		top: 15px;
		left: 80%;
		transform: translate(-20%, -15%);
	}
	#IndexContainer #SlideContainer #QueryList{
		width: 90%;
		max-height: 60vh;
		margin-top: 5px;
		top: 6vh;
	}
	#IndexContainer #SlideContainer #QueryList .QueryListItem{
		width: 100%;
		padding: 2vh 1%;
	}
	#IndexContainer #SlideContainer #QueryList .QueryListItem .QueryListItem_Image{
		display: none;
	}
	#IndexContainer #SlideContainer #QueryList .QueryListItem .QueryListItem_Name{
		font-size: 1em;
	}
	#IndexContainer #CollagesList{
		width: 100%;
		margin-top: 3vh;
		padding-bottom: 15vh;
	}
	#IndexContainer #CollagesList .CollagesListItem{
		padding: 0;
		display: block;
		margin: 3vh auto;
		width: 80vw;
		height: 35vw;
	}
}
